import { Autocomplete } from './Autocomplete'
import { MarkerClustering } from './MarkerClustering'

window.VWFS = window.VWFS || {};
VWFS.poiSelector = VWFS.poiSelector || {};

VWFS.poiSelector.PoiSelector = function (component) {

    var poiPoolPath;
    var disConfig;
    var initialLatitude;
    var initialLongitude;
    var initialZoom;
    var focusZoom;
    var defaultMarkerImagePath = "/etc.clientlibs/bluelabel/clientlibs/clientlib-site-bluelabel/resources/img/hereMapPin.svg"
    var markerImagePath;
    var defaultMarkerImageSelectedPath = "/etc.clientlibs/bluelabel/clientlibs/clientlib-site-bluelabel/resources/img/hereMapSelectedPin.svg"
    var markerImageSelectedPath;
    var emptyDropdownText;
    var infoWindowButtonApplyText;
    var infoWindowButtonAppliedText;
    var infoWindowShowButtonApplyEnabled = false;
    var infoWindowShowButtonWebsiteEnabled = false;
    var openingHoursLabel;
    var productConfig;
    var originalPointsOfInterest;
    var originalPointsOfInterestWithoutRadius;
    var pointsOfInterest;
    var selectedPointOfInterest;
    var maxLocations;

    // FLAGS
    var elementRadiusSearchEnabled = false;
    var elementCompanyNameSearchEnabled = false;
    var elementCompanyNameEnabled = false;
    var elementCompanyLogoViewEnabled = false;
    var elementPlacesNavigationEnabled = false;
    var elementDropdownEnabled = true;
    var elementDetailedResultListEnabled = false;
    var elementAddressEnabled = true;
    var elementPhoneEnabled = true;
    var elementEmailEnabled = true;
    var elementOpenTimeEnabled = true;
    var elementMapEnabled = true;
    var useGeolocation = true;
    var showAllPoisAtStart = false;
    var fitAllPoisAtStart = false;
    var initializing = true;
    var productConfigurator = false;
    var productOfferPage = false;

    var isEmbedded = false;
    var embeddingElement = null;
    var locationPicker = null;
    var hiddenPoiFormField = null;
    var isRequired = false;
    var geoLocationLastUsed = false;

    // MAP, MARKER, INFO-WINDOW
    var map;
    var markerClustering = null;
    var markerClusteringImage = "/etc.clientlibs/bluelabel/clientlibs/clientlib-poiselector/resources/cluster/m";
    var infoWindow;

    // ELEMENTS
    var dropDownPoiResults;
    var dropDownResultsLabel;
    var resultListContainer;
    var resultListTemplate;
    var autoselectInput;
    var radiusInput;
    var placesSearchInput;
    var companyNameSearchInput;
    var addressField;
    var openTimeField;
    var returnOutsideOpeningHoursField;
    var companyLogoField;
    var companyNameField;
    var preselectedDynamicStationEnabled = false;

    var autocomplete;
    var autocompletePlacesNavigation;
    var grantGeoLocationAccessHint;
    var geoLocationAccessDeniedMessage;

    var hintEmptyResults;
    var hintEmptyResultList;
    var hintRequiredError;
    var hintGenericError;
    var isRCOSUsed = false;
    var campaign;
    var mapContainer;

    var autocompleteService;

    var productOfferLocationId;
    var useProductOfferLocationId = false;
    var stationIdApplied = false;
    var carUuid;
    var preselectedStationId;

    var disablePoiSelectorInput = false;
    var isWithinModal = false;

    var firstLoading = true;

    var consentOverlay = null;
    var consentOverlayAcceptanceButton = null;
    var hasConsent = false;
    var initCalled = false;

    var dssUrl = null;

    /**
     * INIT
     */
    this.init = function () {

        // READ DATA ATTRIBUTES FROM COMPONENT
        elementRadiusSearchEnabled = component.getAttribute('data-radius-search-enabled') === "true";
        elementPlacesNavigationEnabled = component.getAttribute('data-places-navigation-enabled') === "true";
        elementCompanyNameSearchEnabled = component.getAttribute('data-company-name-search-enabled') === "true";
        elementCompanyNameEnabled = component.getAttribute('data-company-company-name-enabled') === "true";
        elementCompanyLogoViewEnabled = component.getAttribute('data-company-logo-view-enabled') === "true";
        preselectedDynamicStationEnabled = component.getAttribute("data-poi-preselected-dynamic-station-enabled") === "true";
        elementDropdownEnabled = component.getAttribute('data-dropdown-enabled') === "true";
        elementAddressEnabled = component.getAttribute('data-address-enabled') === "true";
        elementPhoneEnabled = component.getAttribute('data-phone-enabled') === "true";
        elementEmailEnabled = component.getAttribute('data-email-enabled') === "true";
        elementOpenTimeEnabled = component.getAttribute('data-open-time-enabled') === "true";
        elementDetailedResultListEnabled = component.getAttribute('data-use-detailed-result-list') === "true";
        useGeolocation = component.getAttribute('data-use-browser-geolocation') === "true";
        showAllPoisAtStart = component.getAttribute('data-show-all-pois-at-start') === "true";
        fitAllPoisAtStart = component.getAttribute('data-fit-all-pois-at-start') === "true";
        poiPoolPath = component.getAttribute('data-poi-datapool');
        initialLatitude = VWFSUtils.getFloatAttribute(component, 'data-initial-location-latitude', 0);
        initialLongitude = VWFSUtils.getFloatAttribute(component, 'data-initial-location-longitude', 0);
        initialZoom = VWFSUtils.getIntAttribute(component, 'data-initial-zoom', 2);
        focusZoom = VWFSUtils.getIntAttribute(component, 'data-focus-zoom', 15);
        emptyDropdownText = component.getAttribute("data-empty-dropdown-text");
        markerImagePath = component.getAttribute('data-marker-image-path') || defaultMarkerImagePath;
        markerImageSelectedPath = component.getAttribute('data-marker-image-selected-path') || defaultMarkerImageSelectedPath;
        markerClusteringImage = component.getAttribute('data-marker-cluster-image-path') || markerClusteringImage;
        infoWindowButtonApplyText = VWFSUtils.getStringAttribute(component, 'data-button-apply-text', "Apply Selection");
        infoWindowButtonAppliedText = VWFSUtils.getStringAttribute(component, 'data-button-applied-text', "Selected");
        infoWindowShowButtonApplyEnabled = component.getAttribute('data-button-apply-enabled') === "true";
        infoWindowShowButtonWebsiteEnabled = component.getAttribute('data-button-website-enabled') === "true";
        isEmbedded = component.getAttribute('data-embedded') === "true";
        embeddingElement = component.getAttribute("data-embedding-element");
        isRequired = component.getAttribute('data-required') === "true";
        mapContainer = component.querySelector("[data-here-map-container]");
        carUuid = VWFSUtils.getURLParameter("car_uuid");
        disConfig = component.getAttribute("data-dis-config");
        preselectedStationId = component.getAttribute("data-poi-preselected-station-id") || VWFSUtils.getURLParameter("ncbp_station-id");
        isWithinModal = !!document.querySelector(".js-dealership-filter-modal");
        openingHoursLabel = component.getAttribute("data-info-window-opening-hours");
        // GET REQUIRED-ERROR-FIELD
        hintRequiredError = component.querySelector("[data-required-error-message]");
        hintGenericError = component.querySelector("[data-generic-error-message]");
        dssUrl = component.getAttribute("data-cid-url");

        if (component.closest('.js-product-search-form-rental') && component.closest('.js-product-search-form-rental').getAttribute('data-product-search-form-rental-locations-maxcount') !== "0") {
            maxLocations = component.closest('.js-product-search-form-rental').getAttribute('data-product-search-form-rental-locations-maxcount');
        }

        let searchComponent;
        if (VWFSUtils.getParentElementByClass(component, "js-product-search-form-rental") !== null) {
            searchComponent = VWFSUtils.getParentElementByClass(component, "js-product-search-form-rental");
            isRCOSUsed = checkForAttribute(component, searchComponent, 'js-product-search-form-rental', 'data-product-search-form-vehicle-service', 'RCOS');
            if (isRCOSUsed) {
                campaign = checkForAttribute(component, searchComponent, 'js-product-search-form-rental', 'data-product-search-form-rental-campaign', 'Campaign');
            }
        } else if (VWFSUtils.getParentElementByClass(component, "js-advanced-car-search-aem-poi-selector") !== null) {
            searchComponent = VWFSUtils.getParentElementByClass(component, "js-advanced-car-search-aem-poi-selector").previousElementSibling;
            isRCOSUsed = checkForAttribute(component, searchComponent, 'js-advanced-car-search-aem-poi-selector', 'data-vehicle-service', 'RCOS', true);
        }

        // ADD LISTENER > ON REQUIRED ERROR
        component.addEventListener("requiredError", function () {
            if (elementDropdownEnabled) {
                dropDownPoiResults.classList.add('is-error');
            }
            if (elementDetailedResultListEnabled) {
                resultListContainer.classList.add('is-error', 'dmp-is-error-container');
            }
            VWFSUtils.removeClassUHide(hintRequiredError);
        });

        // ADD LISTENER > ON SHOW ERROR WITH A MESSAGE
        component.addEventListener("showError", function (event) {
            hintGenericError.textContent = event.detail.errorMessage;
            if (elementDropdownEnabled) {
                dropDownPoiResults.classList.add('is-error');
            }
            if (elementDetailedResultListEnabled) {
                resultListContainer.classList.add('is-error', 'dmp-is-error-container');
            }
            VWFSUtils.removeClassUHide(hintGenericError);
        });

        component.addEventListener("disablePoiSelector", function () {
            disablePoiSelectorInput = true;
            if (elementDropdownEnabled) {
                dropDownPoiResults.setAttribute('disabled', '');
            }
            if (elementDetailedResultListEnabled) {
                component.querySelectorAll(".dmp-result-list-button-group__item > span").forEach(input => {
                    input.classList.add("is-disabled");
                });
            }
        });

        component.addEventListener("enablePoiSelector", function () {
            disablePoiSelectorInput = false;
            if (elementDropdownEnabled) {
                dropDownPoiResults.removeAttribute('disabled');
            }
            if (elementDetailedResultListEnabled) {
                component.querySelectorAll(".dmp-result-list-button-group__item > span").forEach(input => {
                    input.classList.remove("is-disabled");
                });
            }
        });

        // ADD LISTENER
        component.addEventListener("poipoolChanged", function (event) {
            if (event && event.detail && event.detail.poiPoolPath) {
                poiPoolPath = event.detail.poiPoolPath;

                if (event.detail.locationId) {
                    productOfferLocationId = event.detail.locationId;
                    useProductOfferLocationId = true;
                }

                if (event.detail.preselectedStationId && event.detail.preselectedDynamicStationEnabled === "false") {
                    preselectedStationId = event.detail.preselectedStationId;
                } else if (event.detail.preselectedDynamicStationEnabled !== "false") {
                    preselectedDynamicStationEnabled = event.detail.preselectedDynamicStationEnabled;
                }

                // REMOVE ERROR FROM DROP DOWN IN CASE IT WAS ALREADY SHOWN
                if (elementDropdownEnabled) {
                    removeErrorsDropdown();
                }

                if (elementDetailedResultListEnabled) {
                    removeErrorsResultList();
                }

                if (elementCompanyNameSearchEnabled) {
                    loadPoisWithoutRadius();
                }
                // LOAD LOCATIONS FROM POI-POOL
                loadPois();
                if (preselectedStationId) {
                    preselectPoi();
                }
            }

        });


        if (elementDropdownEnabled || elementRadiusSearchEnabled) {
            hintEmptyResults = component.querySelector("[data-hint-empty-results]");
        }

        if (elementDropdownEnabled) {

            // GET DROPDOWN FIELDS
            dropDownPoiResults = component.querySelector("[data-input-results]");
            dropDownResultsLabel = component.querySelector("[data-label-results]");

            // ADD EVENT LISTENER TO DROPDOWN
            dropDownPoiResults.addEventListener("change", onDropdownPoiChange);

        }

        if (elementDetailedResultListEnabled) {
            // GET RESULT LIST FIELDS
            hintEmptyResultList = component.querySelector("[data-result-list-hint-empty-results]");
            resultListContainer = component.querySelector("[data-result-list-container]")
            resultListTemplate = component.querySelector("[data-result-list-template]")

            if (isWithinModal) {
                resultListContainer.classList.remove('dmp-scroll-container');
                resultListContainer.classList.add('dmp-scroll-container-grey');
            }
        }

        if (elementAddressEnabled || elementPhoneEnabled || elementEmailEnabled) {
            // GET ADDRESS FIELD
            addressField = component.querySelector("[data-address]");

        }

        if (elementOpenTimeEnabled) {

            // GET Opening Time FIELD
            openTimeField = component.querySelector("[data-open-time]");

            // GET RETURN-OUTSIDE-OPENING-HOURS FIELD
            returnOutsideOpeningHoursField = component.querySelector("[data-return-outside-opening-hours]");

        }
        if (elementCompanyLogoViewEnabled) {
            companyLogoField = component.querySelector("[data-logo]");
        }

        if (elementCompanyNameEnabled) {
            companyNameField = component.querySelector("[data-company-name]");
        }

        // CHECK CONSENT
        checkConsent(this);
    }

    this.initHEREMapAfterConsent = async function () {
        if (initCalled) {
            return
        }
        initCalled = true;

        component.addEventListener("componentShown", function () {
            if (map) {
                map.getViewPort().resize()
                if (fitAllPoisAtStart || showAllPoisAtStart || (productConfig && productConfig.poiSelectorRadius)) {
                    markerClustering.fitMapToMarkers();
                } else {
                    let lngLat = {lng: initialLongitude, lat: initialLatitude}

                    if (productConfig && productConfig.poiSelectorLat && productConfig.poiSelectorLng) {
                        lngLat = {lng: productConfig.poiSelectorLng, lat: productConfig.poiSelectorLat};
                    }
                    map.setCenter(lngLat);
                    map.setZoom(initialZoom);
                }
            }
        })

        // ADD LISTENER > RESET SELECTION
        component.addEventListener("resetSelection", function () {
            // REMOVE ERROR FROM DROP DOWN
            resetPoi()
        });

        // SELECTION OPTIONS UPDATED => IN WHICH POI IS SELECTED MODEL AVAILABLE
        component.addEventListener("updatePoiSelection", function (event) {
            updatePoisBasedOnModels(event.detail.pois)
        });

        component.addEventListener("dealerFilterLoaded", filterDealerByPoiUuid);

        if (preselectedDynamicStationEnabled) {
            const searchParams = new URLSearchParams(window.location.search);
            if (searchParams.has('ncbp_transaction-id') && dssUrl) {
                    await getCompanyIdFromDataStorage(searchParams.get('ncbp_transaction-id'), dssUrl);
                if (preselectedStationId) {
                    preselectPoi();
                }
            }

            if(!preselectedStationId){
                component.addEventListener("vehicleDataLoaded", () => {
                    getCompanyIdFromCarJson();
                    if (preselectedStationId) {
                        preselectPoi();
                    }
                });
            }
        }


        // GEOLOCATION ACCESS REQUEST
        let determinedCurrentGeoLocation;
        let permissionState = 'prompt';
        grantGeoLocationAccessHint = component.querySelector("#use-current-location-hint");
        geoLocationAccessDeniedMessage = component.querySelector('#location-denied-error-message');
        if (useGeolocation && elementRadiusSearchEnabled && grantGeoLocationAccessHint && geoLocationAccessDeniedMessage) {

            grantGeoLocationAccessHint.addEventListener("click", function () {

                // SHOW / HIDE LOCATION-ERROR-MESSAGE WHEN PERMISSIONS ARE CHANGED IN BROWSER AFTER INITIAL PROMPT
                navigator.geolocation.getCurrentPosition(function () {
                    permissionState = "granted";
                    geoLocationAccessDeniedMessage.classList.add('u-hide');
                }, function (positionError) {
                    switch (positionError.code) {
                        // PERMISSION_DENIED
                        case 1:
                            permissionState = "denied"
                            geoLocationAccessDeniedMessage.classList.remove('u-hide');
                            determinedCurrentGeoLocation = null;
                            break
                        // POSITION_UNAVAILABLE
                        case 2:
                            geoLocationAccessDeniedMessage.classList.add('u-hide');
                            break
                        // TIMEOUT
                        case 3:
                            determinedCurrentGeoLocation = null;
                            break
                    }
                })

                // GET GEO-LOCATION ADD SUBSCRIBE FOR NOTIFICATION
                VWFS.MapHelper.getGeoLocation(position => {

                    // SET FALLBACK COORDINATES
                    var lngLat;
                    geoLocationLastUsed = true;
                    if (position) {
                        // GET COORDINATES FROM GEOLOCATION
                        lngLat = {lat: position.coords.latitude, lng: position.coords.longitude}
                        determinedCurrentGeoLocation = lngLat;
                    } else if (permissionState === 'granted' && determinedCurrentGeoLocation) {
                        // USER CLICKED AGAIN ON HINT
                        lngLat = determinedCurrentGeoLocation;
                    } else { // USER DENIED THE LOCATION-PERMISSION
                        geoLocationLastUsed = false;
                    }

                    // UPDATE THE PRODUCT-CONFIG IN LOCAL STORAGE WITH GEOLOCATION VALUES
                    if (geoLocationLastUsed) {
                        autoselectInput.value = '';
                        resetCompanyNameField();
                        geoLocationAccessDeniedMessage.classList.add('u-hide');
                    } else {
                        geoLocationAccessDeniedMessage.classList.remove('u-hide');
                        return;
                    }

                    if (elementRadiusSearchEnabled) {
                        // SET CENTER MARKER
                        markerClustering.setCenterMarker(lngLat);

                        loadPois();
                        if (preselectedStationId) {
                            preselectPoi();
                        }
                    }
                }, permissionState === 'prompt');
            });
        }

        if (isEmbedded) {

            // TEST IF POI-SELECTOR IS PART OF LOCATION-PICKER-OPTION
            if (embeddingElement === "locationpicker") {

                locationPicker = VWFSUtils.getParentElementByClass(component, "js-location-picker-poi-selector");
                if (!locationPicker) {
                    console.debug("ERROR ProductLocationPicker.js : Component is embedded in LocationPicker but no LocationPicker was found.");
                }


            } else if (embeddingElement === "contactform") {
                hiddenPoiFormField = component.querySelector("[data-poi-contactform]");
            }
        }

        if (elementCompanyNameSearchEnabled) {
            initCompanyNameSearch();
        }

        // LOAD POIs
        if (elementMapEnabled || elementRadiusSearchEnabled) {

            let key = component.getAttribute("data-here-map-api-key");

            // LOAD HERE-MAP LIBRARY
            VWFS.MapHelper.loadLibrary(function () {
                let platform = new H.service.Platform({
                    'apiKey': key
                })
                autocompleteService = platform.getSearchService();

                if (elementRadiusSearchEnabled) {
                    initRadiusSearch();
                }

                if (elementMapEnabled) {
                    if (elementPlacesNavigationEnabled) {
                        initPlacesNavigation();
                    }

                    initMap(platform);
                }
            });

        } else {
            // LOAD LOCATIONS FROM POI-POOL
            loadPois();
            if (preselectedStationId) {
                preselectPoi();
            }
        }
    }

    const hideOverlay = function (consentOverlay, context) {
        if (consentOverlay) {
            VWFSUtils.setClassUHide(consentOverlay)
            component.classList.remove("poi-selector-overlay-min-height");
        }
        context.initHEREMapAfterConsent()
    }

    const showOverlay = function (consentOverlay) {
        if (consentOverlay) {
            VWFSUtils.removeClassUHide(consentOverlay)
            component.classList.add("poi-selector-overlay-min-height");
        }
    }

    const handleConsentCheck = function (consentState, consentOverlay, context) {
        if (consentState.consent === true && consentState.feedback === "Success") {
            hasConsent = consentState.consent
            hideOverlay(consentOverlay, context)
        } else {
            showOverlay(consentOverlay)
        }
    }

    /**
     * CHECK CONSENT
     */
    const checkConsent = function (context) {
        let consentRequired = component.getAttribute('data-consent-required') === "true";
        consentOverlay = component.querySelector("[data-consent-overlay]");
        consentOverlayAcceptanceButton = component.querySelector("[data-consent-overlay-acceptance-button]");

        // return to init() if consent not required or is given
        if (!consentRequired || hasConsent) {
            hideOverlay(consentOverlay, context)
            return;
        }

        // INITIAL CONSENT CHECK
        if (consentOverlay && consentOverlayAcceptanceButton) {
            if (VWFS.hasOwnProperty('getConsentState')) {
                VWFS.getConsentState("Here_Map").then(consentState => handleConsentCheck(consentState, consentOverlay, context))
            } else {
                window.addEventListener('consentInitialized', async () => {
                    VWFS.getConsentState("Here_Map").then(consentState => handleConsentCheck(consentState, consentOverlay, context))
                })
            }
        }

        window.addEventListener('consentChanged', async function () {
            if (VWFS.hasOwnProperty('getConsentState')) {
                VWFS.getConsentState("Here_Map").then(consentState => handleConsentCheck(consentState, consentOverlay, context))
            }
        })

        if (consentOverlayAcceptanceButton) {
            consentOverlayAcceptanceButton.addEventListener("click", () => {
                // SET CONSENT TO COOKIE if Cookie consent tool is initialized
                if (VWFS.hasOwnProperty('getConsentState')) {
                    VWFS.setConsentState("Here_Map", true);
                } else {
                    // if not, then hide the overlay upon clicking the button
                    hideOverlay(consentOverlay, context)
                }
                VWFSUtils.dispatchEvent(window, "consentChanged");
            });
        }
    }


    /**
     * INIT RADIUS SEARCH
     */
    const initRadiusSearch = function () {

        // GET INPUT-FIELS
        autoselectInput = component.querySelector("[data-input-keyword]");
        radiusInput = component.querySelector("[data-input-radius]");

        // ADD LISTENER TO HANDLE ENTER KEY
        autoselectInput.addEventListener('keydown', function (evt) {
            if (event.which === 13 || event.keyCode === 13) {
                evt.preventDefault();
                return false;
            }
        });

        // GET COUNTRY CODE
        const countryCode = component.getAttribute("data-iso3-country-code");

        // ADD AUTOCOMPLETE TO INPUT-FIELD
        autocomplete = new Autocomplete(autocompleteService, autoselectInput, countryCode, onPlaceSelected);

        // ADD LISTENER TO AND RADIUS-SELECT
        radiusInput.addEventListener("change", onPlaceSelected);

        // ENABLE FIELDS
        autoselectInput.removeAttribute("disabled");
        if (!disablePoiSelectorInput) {
            autoselectInput.removeAttribute("disabled");
        }
        radiusInput.removeAttribute("disabled");

    }

    /**
     * INIT PLACES NAVIGATION
     */
    const initPlacesNavigation = function () {

        // GET INPUT-FIELS
        placesSearchInput = component.querySelector("[data-input-places-navigation-keyword]");

        // ADD LISTENER TO HANDLE ENTER KEY
        placesSearchInput.addEventListener('keydown', function (evt) {
            if (event.which === 13 || event.keyCode === 13) {
                evt.preventDefault();
                return false;
            }
        });

        // GET COUNTRY CODE
        var countryCode = component.getAttribute("data-iso3-country-code");

        // ADD AUTOCOMPLETE TO INPUT-FIELD
        autocompletePlacesNavigation = new Autocomplete(autocompleteService, placesSearchInput, countryCode, onPlaceNavigationSelected);
    }

    const initCompanyNameSearch = function () {
        // GET INPUT
        companyNameSearchInput = component.querySelector("[data-input-company-name-keyword]");
        companyNameSearchInput.removeAttribute("disabled");

        let _debounceFunction = (func, wait) => {
            let timeout;

            return function executedFunction(...args) {
                const later = () => {
                    clearTimeout(timeout);
                    func(...args);
                };

                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
            };
        };

        let requestDataDebounced = _debounceFunction(() => {
            let filteredPois;
            if (originalPointsOfInterestWithoutRadius) {
                filteredPois = filterLocationsWithCompanyName(originalPointsOfInterestWithoutRadius);
            } else {
                filteredPois = filterLocationsWithCompanyName(originalPointsOfInterest);
            }
            updatePois(filteredPois);
        }, 500);

        companyNameSearchInput.addEventListener('keydown', function () {
            requestDataDebounced();
        });
    }

    const filterLocationsWithCompanyName = function (locations) {
        let inputCompanyNameSearch = companyNameSearchInput.value
        if (inputCompanyNameSearch) {
            if (elementRadiusSearchEnabled) {
                autoselectInput.value = '';
            } else if (elementPlacesNavigationEnabled) {
                placesSearchInput.value = '';
            }
            return locations.filter(poi => poi.companyName &&
                (poi.companyName.toLowerCase().indexOf(
                        inputCompanyNameSearch.toLowerCase()) !== -1 ||
                    poi.title.toLowerCase().indexOf(
                        inputCompanyNameSearch.toLowerCase()) !== -1));
        }
        return originalPointsOfInterest;
    }

    /**
     * INIT MAP
     * Initiates the here map after script is loaded.
     */
    const initMap = function (platform) {
        const languageCode = VWFSUtils.getMetaContent("datalayer-language");
        const countryCode = VWFSUtils.getMetaContent("datalayer-countryCode");

        const engineType = H.Map.EngineType['HARP'];

        // Obtain the default map types from the platform object:
        const defaultLayers = platform.createDefaultLayers({engineType: engineType, lg: languageCode});
        // Instantiate (and display) a map object:
        map = new H.Map(mapContainer, defaultLayers.vector.normal.map, {
            zoom: initialZoom,
            center: {lat: initialLatitude, lng: initialLongitude},
            pixelRatio: window.devicePixelRatio || 1,
            padding: {top: 100, left: 50, bottom: 50, right: 50},
            engineType: engineType
        });
        // add a resize listener to make sure that the map occupies the whole container
        window.addEventListener('resize', () => map.getViewPort().resize());

        // Enable the event system on the map instance:
        const mapEvents = new H.mapevents.MapEvents(map);

        // Instantiate the default behavior, providing the mapEvents object:
        const behavior = new H.mapevents.Behavior(mapEvents);

        // disable wheel zoom behavior and implement own one to scroll the page instead
        behavior.disable(H.mapevents.Behavior.WHEELZOOM)
        map.addEventListener('wheel', function (evt) {
            window.scrollBy({left: 0, top: evt.originalEvent.deltaY, behavior: "instant"});
        });

        // disable dragging on touch and with one pointer (
        let startY;
        map.addEventListener('dragstart', function (evt) {
            if (evt.currentPointer.type === 'touch' && evt.pointers.length < 2) {
                startY = evt.currentPointer.viewportY;
                behavior.disable(H.mapevents.Behavior.DRAGGING);
            }
        });

        map.addEventListener('drag', function (evt) {
            if (evt.currentPointer.type === 'touch' && evt.pointers.length < 2) {
                window.scrollBy({left: 0, top: (startY - evt.currentPointer.viewportY), behavior: "instant"});
            }
        });

        map.addEventListener('dragend', function () {
            behavior.enable(H.mapevents.Behavior.DRAGGING);
        });

        let code = new H.ui.i18n.Localization("en-US");
        if (languageCode && countryCode) {
            try {
                code = new H.ui.i18n.Localization(languageCode + "-" + countryCode);
            } catch (e) {
                //do nothing and use default
                console.debug("Default local is used (en-US) - local not supported: {}", e)
            }
        }

        // Create the default UI:
        const ui = H.ui.UI.createDefault(map, defaultLayers, code);

        ui.getControl('mapsettings').setVisibility(false)

        // CREATE MARKER-CLUSTER-OPTIONS OBJECT
        markerClustering = new MarkerClustering(map, ui, onClickPoiOnMap, markerClusteringImage, markerImagePath, markerImageSelectedPath)

        // CREATE MAP
        map.getEngine().addEventListener('render', (evt) => {
            if (map.getEngine() === evt.target) {
                let spinner = component.querySelector(".c-spinner__loader");
                if (spinner) {
                    spinner.classList.add("u-hide");
                    spinner.parentElement.classList.remove("c-spinner--section");
                }
            }
        })
        // SET FALLBACK COORDINATES
        let lngLat = {lng: initialLongitude, lat: initialLatitude}

        if (productConfig && productConfig.poiSelectorLat && productConfig.poiSelectorLng) {
            lngLat = {lng: productConfig.poiSelectorLng, lat: productConfig.poiSelectorLat};
        }

        if (elementRadiusSearchEnabled) {

            // SET CENTER MARKER
            markerClustering.setCenterMarker(lngLat);

            // LOAD POI DATA FROM POI-POOL
            if (productConfig && (productConfig.x || productConfig.poiSelectorRadius)) {

                if (productConfig.poiSelectorKey && productConfig.poiSelectorKey !== 'geolocation') {
                    // values is set in case of initializing page with values
                    autoselectInput.value = productConfig.poiSelectorKey;

                    // set place object to autocomplete
                    setAutoCompleteToInput(productConfig.poiSelectorKey);
                }

                if (productConfig.poiSelectorRadius) {
                    radiusInput.value = productConfig.poiSelectorRadius || 50;
                    showAllPoisAtStart = false;
                }

            } else {
                if (elementCompanyNameSearchEnabled) {
                    loadPoisWithoutRadius();
                }
                // LOAD POI DATA FROM POI-POOL
                loadPois();
                if (preselectedStationId) {
                    preselectPoi();
                }
            }

        } else {

            if (fitAllPoisAtStart || showAllPoisAtStart) {
                markerClustering.fitMapToMarkers();
            } else {
                map.setCenter(lngLat);
                map.setZoom(initialZoom);
            }

            // LOAD POI DATA FROM POI-POOL
            loadPois();
            if (preselectedStationId) {
                setTimeout((function () {
                    preselectPoi();
                }), 300);
            }

            // LOAD POI DATA FROM POI-POOL
            if (elementPlacesNavigationEnabled && productConfig && productConfig.poiSelectorKey) {

                // productConfig is set in case of initializing page with values
                placesSearchInput.value = productConfig.poiSelectorKey;

                // set place object to autocomplete
                setAutoCompleteToInput(productConfig.poiSelectorKey, true);

            }
        }

        component.addEventListener("removeAllMarkers", function () {
            setTimeout(function () {
                removeAllMarker();
                if (!elementRadiusSearchEnabled) {
                    map.setCenter({
                        lat: parseFloat(initialLatitude), lng: parseFloat(initialLongitude)
                    });
                    map.setZoom(initialZoom);
                }
            }, 100);
        });
        VWFSUtils.dispatchEvent(component, "poiSelectorReady");
    }

    /**
     * simulate user input in autocomplete box by finding and setting place
     * @param poiId string for autocomplete input
     * @param placesNavigation false if radiusSearch enabled, true if placesNavigaton enabled
     */
    const setAutoCompleteToInput = function (poiId, placesNavigation = false) {
        autocompleteService.lookup({id: poiId}, (place) => {
            if (place) {
                if (placesNavigation) {
                    autocompletePlacesNavigation.setPlace(place);
                } else {
                    autocomplete.setPlace(place);
                }
            }
        })

    }

    const loadPoisWithoutRadius = function () {
        if (!poiPoolPath) {
            console.debug("INFO: No poiPoolPath available");
            return;
        }
        let poiPoolUri = poiPoolPath;
        if (isRCOSUsed) {
            poiPoolUri = VWFSUtils.addUrlParam(poiPoolUri, "rcos", "true")
        }

        let request = new XMLHttpRequest();
        request.addEventListener('load', function () {

            if (request.status >= 200 && request.status < 300) {
                // GET POI-JSON FROM RESPONSE AND PARSE IT
                originalPointsOfInterestWithoutRadius = JSON.parse(request.response)

                VWFSUtils.saveDealerInformationServicePoisToSessionStorage(disConfig, originalPointsOfInterestWithoutRadius, null, null, null);
            } else {
                console.log("ERROR: Request to get POIs failed. Path: '" + poiPoolPath + "'.'");
            }

        });
        request.open("GET", poiPoolUri, false);
        request.send();
    }


    /**
     * LOAD POIS
     */
    const loadPois = function () {

        if (!poiPoolPath) {
            console.debug("INFO: No poiPoolPath available");
            return;
        }

        let lat, lng, radius;
        let poiPoolUri = poiPoolPath;
        if (elementRadiusSearchEnabled && !(showAllPoisAtStart && firstLoading && markerClustering)) {
            if (markerClustering) {
                let centerMarker = markerClustering.getCenterMarker();
                if (!centerMarker) {
                    return;
                }
                lat = centerMarker.getGeometry().lat;
                lng = centerMarker.getGeometry().lng;
                poiPoolUri = VWFSUtils.addUrlParam(poiPoolUri, "lat", lat);
                poiPoolUri = VWFSUtils.addUrlParam(poiPoolUri, "lon", lng);
                // don't include the radius limitation in case of preselection of a station
                if (!preselectedStationId || stationIdApplied) {
                    poiPoolUri = VWFSUtils.addUrlParam(poiPoolUri, "rad", radiusInput.value)
                    radius = radiusInput.value;
                }
            } else {
                return;
            }
        }
        if (isRCOSUsed) {
            poiPoolUri = VWFSUtils.addUrlParam(poiPoolUri, "rcos", "true")
        }

        firstLoading = false;

        let request = new XMLHttpRequest();
        request.addEventListener('load', function () {

            if (request.status >= 200 && request.status < 300) {
                // GET POI-JSON FROM RESPONSE AND PARSE IT
                originalPointsOfInterest = JSON.parse(request.response)
                filterAndUpdatePois()

                VWFSUtils.saveDealerInformationServicePoisToSessionStorage(disConfig, originalPointsOfInterest, lat, lng, radius);
            } else {
                console.log("ERROR: Request to get POIs failed. Path: '" + poiPoolPath + "'.'");
            }

        });
        request.open("GET", poiPoolUri, false);
        request.send();

    }

    const filterAndUpdatePois = function () {
        if (campaign) {
            originalPointsOfInterest = originalPointsOfInterest.filter(poi => poi.campaigns.includes(campaign))
        }

        originalPointsOfInterest.forEach(poi => {
            poi.selected = selectedPointOfInterest && poi.poiUuid === selectedPointOfInterest.poiUuid;
        });

        if (elementCompanyNameSearchEnabled && companyNameSearchInput.value) {
            //search with filter only if more than 0 are available
            let filteredPois;
            if (originalPointsOfInterestWithoutRadius) {
                filteredPois = filterLocationsWithCompanyName(originalPointsOfInterestWithoutRadius);
            } else {
                filteredPois = filterLocationsWithCompanyName(originalPointsOfInterest);
            }
            if (filteredPois.length > 0) {
                updatePois(filteredPois);
            } else {
                companyNameSearchInput.value = '';
                updatePois(originalPointsOfInterest);
            }
        } else {
            updatePois(originalPointsOfInterest);
        }

        if (preselectedDynamicStationEnabled) {
            let carJson = JSON.parse(VWFSUtils.getItemFromSessionStorage(carUuid));
            if (carJson && carJson.vehicleData && carJson.vehicleData.model && carJson.vehicleData.model.companyId) {
                preselectedStationId = carJson.vehicleData.model.companyId;
            }
        }

        stationIdApplied = true;

        if (locationPicker) {
            VWFSUtils.dispatchEvent(locationPicker, "poisLoaded", {detail: {locations: pointsOfInterest}});
        } else {
            VWFSUtils.dispatchEvent(component, "poisLoaded", {detail: {locations: pointsOfInterest}});
        }
    }

    const setDealerData = function (poi) {

        let carJson = JSON.parse(VWFSUtils.getItemFromSessionStorage(carUuid));
        let currentLocation = poi

        let dealerData = {};

        if (currentLocation && currentLocation.title) {
            dealerData.name = currentLocation.title;
        } else {
            dealerData.name = "";
        }

        if (currentLocation.contactData.emails) {
            if (currentLocation.contactData.emails.length > 0) {
                currentLocation.contactData.email = currentLocation.contactData.emails[0];
            } else {
                currentLocation.contactData.email = ""
            }
            delete currentLocation.contactData.emails;
        }

        if (currentLocation.contactData.website) {
            delete currentLocation.contactData.website;
        }

        if (currentLocation.contactData) {
            // ADD ADDRESS
            dealerData.address = {};
            dealerData.address.street = currentLocation.address.street;
            dealerData.address.houseNumber = currentLocation.address.houseNumber;
            dealerData.address.zipCode = currentLocation.address.zipCode;
            dealerData.address.city = currentLocation.address.city;
            dealerData.address.countryCode = currentLocation.address.countryCode;

        }
        dealerData.contactData = currentLocation.contactData;
        dealerData.companyId = currentLocation.companyId;
        dealerData.regionId = currentLocation.regionId;
        dealerData.groupId = currentLocation.groupId;
        dealerData.companyIdKVPS = currentLocation.companyId;		// just a copy of companyId for now.
        dealerData.companyName = currentLocation.companyName;
        if (dealerData.contactData.telephoneNumber) {
            dealerData.contactData.telephoneNumber = dealerData.contactData.telephoneNumber.replace(/\D/g, "");
        }
        if (currentLocation.openTime) {
            let openingHours = [];
            let weekdays = VWFSUtils.getWeekdayMap();
            currentLocation.openTime.forEach((time, i) => {
                if (time.opening && time.closing) {
                    openingHours.push({
                        opens: time.opening,
                        closes: time.closing,
                        dayOfWeek: weekdays[i - 1]
                    });
                }
            });
            if (openingHours.length > 0) {
                dealerData.openingHours = openingHours;
            }
        }

        // SET LOCATION-DATA
        carJson.dealerData = dealerData;

        if (!carJson.customNbwData) {
            carJson.customNbwData = {}
        }
        carJson.customNbwData.preselectedPoi = true;
        carJson.customNbwData.poiSelection = {};
        carJson.customNbwData.poiSelection.locationId = currentLocation.poiUuid;

        carJson.vehicleData.delivery.deliveryType = 'DEALERSHOP';

        VWFSUtils.setItemToSessionStorage(carUuid, JSON.stringify(carJson))
        VWFSUtils.dispatchEvent(document, 'dealerDataUpdated');
    }

    const getCompanyIdFromCarJson = function () {
        let carJson = JSON.parse(VWFSUtils.getItemFromSessionStorage(carUuid));
        if (preselectedDynamicStationEnabled && !preselectedStationId && carJson && VWFSUtils.getObjectPathExists(carJson, 'vehicleData.model.companyId')) {
            preselectedStationId = carJson.vehicleData.model.companyId;
        }
    }
    /**
     * In case of the poidata is included in contact form and poi preselection is enabled,
     * we request companyId from bff data-storage service for URL Param ncbp_transaction-id
     */
    const getCompanyIdFromDataStorage = async function (transactionId, dssUrl) {

        let url = `${dssUrl}?transaction-id=${transactionId}`

        try {
            let response = await fetch(url, {
                method: 'GET'
            })
            let formResponse = await response.json();
            if(response.ok && (formResponse.dealerData && formResponse.dealerData.companyId)) {
                    preselectedStationId = formResponse.dealerData.companyId;
            } else {
                console.debug('ERROR data storage service: ', formResponse.message);
            }
        } catch (err) {
            console.error('ERROR while retrieving companyId: ', err);
        }
    }


    /**
     * select a poi in result list by checking the input and changing text and style oof button
     * @param button - button of selected result list item
     * @param scrollIntoView - if true (and desktop view): result list item is scrolled into view
     */
    const selectPoiInResultList = function (button, scrollIntoView) {
        if (button) {
            button.checked = true;
            component.querySelectorAll(".dmp-result-list-button-group__label-selected").forEach(span => {
                span.classList.remove("dmp-result-list-button-group__label-selected")
                span.textContent = infoWindowButtonApplyText;
            });
            button.nextElementSibling.classList.add("dmp-result-list-button-group__label-selected");
            button.nextElementSibling.textContent = infoWindowButtonAppliedText;

            // only scroll to address if not in mobile view where the map and result list containers are stacked
            let stackedView = resultListContainer.offsetTop - mapContainer.offsetTop > 100;
            if (scrollIntoView && !stackedView) {
                button.closest(".c-item-teaser").scrollIntoView({block: "center"});
            }
        }
    }

    const deselectPoiInResultList = function () {
        component.querySelectorAll(".dmp-result-list-button-group__input").forEach(input => {
            input.checked = false;
        });
        component.querySelectorAll(".dmp-result-list-button-group__label-selected").forEach(span => {
            span.classList.remove("dmp-result-list-button-group__label-selected")
            span.textContent = infoWindowButtonApplyText;
        });
    }

    const updatePois = function (pois, filter = false) {
        pointsOfInterest = pois;
        // UPDATE POIs ON MAP
        if (elementMapEnabled) {
            if (filter) {
                filterPoisOnMap(pointsOfInterest, !(initializing && productConfig && (productConfig.poiUuid || productOfferLocationId)));
            } else {
                updatePoisOnMap(pointsOfInterest, !(initializing && productConfig && (productConfig.poiUuid || productOfferLocationId)));
            }
        }

        // UPDATE DROPDOWN
        if (elementDropdownEnabled) {
            updatePoisOnDropdown(pointsOfInterest);
        }

        if (elementDetailedResultListEnabled) {
            updateDetailedResultList(pointsOfInterest);
        }

        // ENABLE PLACES NAVIGATION
        if (elementPlacesNavigationEnabled) {
            placesSearchInput.removeAttribute("disabled");
        }

        // Company Logo
        if (elementCompanyLogoViewEnabled) {
            updateCompanyLogoField(null);
        }

        // Company Logo
        if (elementCompanyNameEnabled) {
            updateCompanyNameField(null);
        }

        // ADDRESS FIELD
        if (elementAddressEnabled) {
            updateAddressField(null);
        }

        // OPEN TIME FIELD
        if (elementOpenTimeEnabled) {
            updateOpenTimeField(null);
        }

        // ON FIRST CALL: INITIALIZE WITH CHOSEN POI IF STORED IN LOCAL STORAGE
        if (productOfferLocationId && useProductOfferLocationId && initializing) {
            initWithValue(productOfferLocationId);
            initializing = false;
            useProductOfferLocationId = false;
            return;
        } else if (initializing) {
            if (productConfig && productConfig.poiUuid) {
                initWithValue(productConfig.poiUuid);
                initializing = false;
                return;
            } else {
                initializing = false;
            }
        }

        if (selectedPointOfInterest && pointsOfInterest.some(item => item.poiUuid === selectedPointOfInterest.poiUuid)) {
            if (elementDropdownEnabled) {
                // ITERATE OPTIONS OF DROPDOWN
                Array.from(dropDownPoiResults.children).forEach(option => {

                    // SET DROPDOWN ITEM
                    if (option.pointOfInterest && option.pointOfInterest.poiUuid === selectedPointOfInterest.poiUuid) {
                        dropDownPoiResults.value = option.value;
                    }

                });
            }
            if (elementDetailedResultListEnabled) {
                // SET SELECTED POI IN RESULT LIST
                let buttonInResultList = component.querySelector('#' + selectedPointOfInterest.poiUuid);
                if (buttonInResultList) {
                    onResultListClick(buttonInResultList, true);
                }
            }
            applyPoi(selectedPointOfInterest);
        } else {

            // DISPATCH EVENT (remove selected address)
            if (locationPicker) {
                VWFSUtils.dispatchEvent(locationPicker, "locationChanged", {detail: {location: null}});
            } else {
                VWFSUtils.dispatchEvent(component, "locationChanged", {detail: {location: null}});
            }
        }
    }

    /**
     * simulate click on poi in dropdown and set values for
     * @param poiUuid of chosen poi
     */
    const initWithValue = function (poiUuid) {

        var selectedPoi;
        var valid = false;

        if (elementDropdownEnabled) {

            // GET SELECTED OPTION FROM DROPDOWN
            var selectedOption = Array.from(dropDownPoiResults.options).find(option => option.pointOfInterest && option.pointOfInterest.poiUuid === poiUuid);

            // SET SELECTED INDEX
            dropDownPoiResults.selectedIndex = selectedOption ? selectedOption.index : 0;

            valid = dropDownPoiResults.selectedIndex !== 0;

            if (selectedOption) {
                selectedPoi = selectedOption.pointOfInterest;
            }

        } else if (elementDetailedResultListEnabled) {
            // GET BUTTON OF SELECTED POI AND CLICK IT
            let buttonInResultList = component.querySelector('#' + poiUuid);
            if (buttonInResultList) {
                onResultListClick(buttonInResultList, true);
                selectedPoi = buttonInResultList.poi;

                valid = true;
            }

        } else {

            selectedPoi = pointsOfInterest.find(poi => poi.poiUuid === poiUuid);
            valid = true;
        }

        // APPLY POI
        if (selectedPoi) {
            // SET VALIDATION FLAG
            setValidationFlag(valid);
            applyPoi(selectedPoi);
        }
    };

    /**
     * UPDATE POIs ON MAP
     * Set Point of Interests on the Map.
     *
     * @param pointsOfInterest
     * @param fitToMap determines if the zoom of the map is adjusted to show all available markers or not
     */
    const filterPoisOnMap = function (pointsOfInterest, fitToMap) {
        pointsOfInterest.forEach(poi => poi.disabled = false);
        let differencePois = originalPointsOfInterest.filter(x => pointsOfInterest.indexOf(x) === -1);
        differencePois.forEach(poi => poi.disabled = true);

        markerClustering.setMarkers(pointsOfInterest.concat(differencePois));

        // ADJUST THE MAP, THAT ALL POI ARE INSIDE OF THE MAP
        if (fitToMap && (elementRadiusSearchEnabled || fitAllPoisAtStart)) {
            markerClustering.fitMapToMarkers();
        }
    }
    const updatePoisOnMap = function (pointsOfInterest, fitToMap) {
        let locations = pointsOfInterest.slice();
        if (maxLocations) {
            let availableLocations = Math.min(locations.length, maxLocations);
            locations = locations.splice(0, availableLocations);
        }
        markerClustering.setMarkers(locations);

        // ADJUST THE MAP, THAT ALL POI ARE INSIDE OF THE MAP
        if (fitToMap && (elementRadiusSearchEnabled || fitAllPoisAtStart)) {
            markerClustering.fitMapToMarkers();
        }
    }


    /**
     * UPDATE POIs ON DROPDOWN after Model choice on modelPicker
     *
     * @param selectedPois
     */
    const updatePoisBasedOnModels = function (selectedPois) {
        if (selectedPois.StationIdsForModel) {
            let filteredPois = originalPointsOfInterest.filter(poi => selectedPois.StationIdsForModel.stationIds.includes(disConfig ? poi.companyId : poi.stationNumber))
            updatePois(filteredPois, true);
        }
    }
    /**
     * UPDATE POIs ON DROPDOWN
     *
     * @param pointsOfInterest
     */
    const updatePoisOnDropdown = function (pointsOfInterest) {

        // REMOVE CONTENT FROM SELECT-ELEMENT
        dropDownPoiResults.innerHTML = "";

        // CREATE EMPTY-OPTION ELEMENT
        var emptyOptionElement = document.createElement('option');
        emptyOptionElement.value = "";
        emptyOptionElement.style = "display:none";
        emptyOptionElement.innerHTML = emptyDropdownText;
        dropDownPoiResults.appendChild(emptyOptionElement);

        let availableLocations = pointsOfInterest.length;
        if (maxLocations) {
            availableLocations = Math.min(pointsOfInterest.length, maxLocations);
        }


        // ITERATE POIs AND POPULATE SELECT
        pointsOfInterest.every((pointOfInterest, index) => {

            if (index >= availableLocations) {
                return false;
            }

            // CREATE OPTION ELEMENT
            var optionElement = document.createElement('option');
            optionElement.value = pointOfInterest.title;
            optionElement.innerHTML = pointOfInterest.title;
            optionElement.pointOfInterest = pointOfInterest;

            // ADD OPTION TO SELECT-ELEMENT
            dropDownPoiResults.appendChild(optionElement);
            return true;

        });

        // UPDATE RESULTS LABEL TEXT
        dropDownResultsLabel.innerText = dropDownResultsLabel.getAttribute("data-label-results") + " (" + availableLocations + ")" + (isRequired ? " *" : "");

        // SHOW OR HIDE EMPTY-RESULTS-HINT
        if (pointsOfInterest.length > 0) {
            VWFSUtils.setClassUHide(hintEmptyResults);
        } else {
            VWFSUtils.removeClassUHide(hintEmptyResults);
        }

        // ENABLE RESULT DROP-DOWN
        if (dropDownPoiResults.hasAttribute("disabled")) {
            if (!disablePoiSelectorInput) {
                dropDownPoiResults.removeAttribute("disabled");
            }

        }
    }

    const updateDetailedResultList = function (pointsOfInterest) {
        // remove all old entries in result list container besides error messages
        while (!resultListContainer.lastElementChild.classList.contains("c-error-message")) {
            resultListContainer.removeChild(resultListContainer.lastElementChild);
        }

        // show/remove error message depending of number of points of interest in array
        if (pointsOfInterest.length > 0) {
            VWFSUtils.setClassUHide(hintEmptyResultList);
        } else {
            VWFSUtils.removeClassUHide(hintEmptyResultList);
            return;
        }

        // add a new result list item to container for each entry in pointsOfInterest array
        pointsOfInterest.forEach((poi, index, array) => {

            let resultListItem = new VWFS.poiSelector.ResultListItemFactory(poi, resultListTemplate, openingHoursLabel, addOpeningHoursToList, toggleOpeningHoursList, infoWindowShowButtonWebsiteEnabled, onResultListClick, index + 1 === array.length, getLabelContentLink(), elementAddressEnabled, elementPhoneEnabled, elementEmailEnabled)
                .buildResultListItem();

            resultListContainer.appendChild(resultListItem);
        });


    };


    /**
     * REMOVE ALL MARKERS
     */
    const removeAllMarker = function () {

        markerClustering.setMarkers([]);

    }

    /**
     * SET FOCUS
     * Moves the viewport of the map to a specific location.
     *
     * @param location
     */
    const setFocus = function (location) {
        // CENTER MAP
        map.setCenter(location);
        map.setZoom(focusZoom);
    }

    /**
     * UPDATE Company Logo
     *
     * @param pointOfInterest
     */
    const updateCompanyLogoField = function (pointOfInterest) {

        var companyLogoUrl = getCompanyLogo(pointOfInterest);

        companyLogoField.innerHTML = "";

        if (!companyLogoUrl) {
            VWFSUtils.setClassUHide(companyLogoField);
        } else {
            VWFSUtils.removeClassUHide(companyLogoField);
            companyLogoField.append(companyLogoUrl);
        }
    }

    /**
     * UPDATE Company Name
     *
     * @param pointOfInterest
     */
    const updateCompanyNameField = function (pointOfInterest) {

        var companyNameString = getCompanyName(pointOfInterest);

        companyNameField.innerHTML = "";

        if (!companyNameString) {
            VWFSUtils.setClassUHide(companyNameField);
        } else {
            VWFSUtils.removeClassUHide(companyNameField);
            companyNameField.append(companyNameString);
        }
    }

    const resetCompanyNameField = function () {
        if (companyNameSearchInput) {
            companyNameSearchInput.value = "";
        }
    }

    /**
     * UPDATE ADDRESS FIELD
     *
     * @param pointOfInterest
     */
    const updateAddressField = function (pointOfInterest) {
        let addressString;
        let contactDataString;

        if (elementAddressEnabled) {
            addressString = getAddress(pointOfInterest);
        }

        if (elementEmailEnabled || elementPhoneEnabled) {
            contactDataString = getContactData(pointOfInterest);
        }

        addressField.innerHTML = "";


        if (!addressString && !contactDataString) {
            VWFSUtils.setClassUHide(addressField);
            return;
        } else {
            VWFSUtils.removeClassUHide(addressField);

            if (addressString) {
                addressField.append(addressString);
            }

            if (contactDataString) {
                addressField.append(contactDataString);
            }
        }


    }

    /**
     * UPDATE OPEN TIME FIELD
     *
     * @param pointOfInterest
     */
    const updateOpenTimeField = function (pointOfInterest) {
        if (pointOfInterest != null && pointOfInterest.openTime) {
            getOpenTimeStringFromOpenTimeData(openTimeField, pointOfInterest);
            VWFSUtils.removeClassUHide(openTimeField);
        } else {
            VWFSUtils.setClassUHide(openTimeField);
        }

        if (pointOfInterest != null && pointOfInterest.returnOutsideOpeningHours && component.getAttribute("data-return-outside-opening-hours")) {
            returnOutsideOpeningHoursField.textContent = component.getAttribute("data-return-outside-opening-hours");
            VWFSUtils.removeClassUHide(returnOutsideOpeningHoursField);
        } else {
            VWFSUtils.setClassUHide(returnOutsideOpeningHoursField);
        }

    }


    /**
     * GET OPEN TIME STRING FROM OPEN TIME DATA
     * Generates and returns an openTime-string from JSON-data.
     *
     * @param openTimeField
     * @param pointOfInterest
     */
    const getOpenTimeStringFromOpenTimeData = function (openTimeField, pointOfInterest) {
        for (var i = 0; i < pointOfInterest.openTime.length; i++) {
            var time = pointOfInterest.openTime[(i + 1) % 7];
            if (time.opening && time.closing) {
                openTimeField.querySelector('span[data-day-' + i + ']').innerHTML = ": " + time.opening + " - " + time.closing;
                openTimeField.querySelector('span[data-day-' + i + ']').parentElement.classList.remove("u-hide");
            } else {
                openTimeField.querySelector('span[data-day-' + i + ']').parentElement.classList.add("u-hide");
            }
        }
    }

    /**
     * GET POI AS JSON FROM LOCATION DATA
     * Generates a JSON-string from the POI-data for the contact-form.
     *
     * @param poiDataType
     * @param locationData
     */
    const getPoiAsJsonFromLocationData = function (locationData, poiDataType) {

        var poiData = {};

        switch (poiDataType) {

            case "company":

                // ADD COPANY DATA
                poiData.companyId = locationData.companyId;
                poiData.companyIdKVPS = locationData.companyIdKVPS;
                poiData.companyName = locationData.companyName;

                if (locationData.contactData) {

                    // ADD CONTACT DATA
                    poiData.contactData = {};
                    poiData.contactData.email = locationData.contactData.emails[0];
                    poiData.contactData.telephoneCountryCode = locationData.contactData.telephoneCountryCode;
                    poiData.contactData.telephoneNumber = locationData.contactData.telephoneNumber;

                }

                break;

            case "dealer":

                // ADD DEALER DATA
                poiData.name = locationData.title;

                break;
        }

        if (locationData.contactData) {

            // ADD ADDRESS
            poiData.address = {};
            poiData.address.street = locationData.address.street;
            poiData.address.houseNumber = locationData.address.houseNumber;
            poiData.address.zipCode = locationData.address.zipCode;
            poiData.address.city = locationData.address.city;
            poiData.address.countryCode = locationData.address.countryCode;

        }

        return JSON.stringify(poiData);

    }

    /**
     * APPLY POI
     * When a POI is selected in Dropdown or Map.
     *
     * @param pointOfInterest
     */
    const applyPoi = function (pointOfInterest) {
        selectedPointOfInterest = pointOfInterest;
        pointsOfInterest.forEach(poi => {
            poi.selected = selectedPointOfInterest && poi.poiUuid === selectedPointOfInterest.poiUuid;
        });
        markerClustering.setMarkers(pointsOfInterest)

        // UPDATE Company LOGO FIELD
        if (elementCompanyLogoViewEnabled) {
            updateCompanyLogoField(pointOfInterest);
        }

        // UPDATE Company NAME FIELD
        if (elementCompanyNameEnabled) {
            updateCompanyNameField(pointOfInterest);
        }

        // UPDATE ADDRESS FIELD
        if (elementAddressEnabled || elementEmailEnabled || elementPhoneEnabled) {
            updateAddressField(pointOfInterest);
        }

        // UPDATE OPEN TIME FIELD
        if (elementOpenTimeEnabled) {
            updateOpenTimeField(pointOfInterest);
        }

        // UPDATE HERE-MAP
        if (elementMapEnabled) {

            setFocus({
                lat: parseFloat(pointOfInterest.coordinates.latitude),
                lng: parseFloat(pointOfInterest.coordinates.longitude)
            });
        }

        if (elementDropdownEnabled) {
            removeErrorsDropdown();
        }

        if (elementDetailedResultListEnabled) {
            removeErrorsResultList();
        }

        if (preselectedDynamicStationEnabled) {
            setDealerData(pointOfInterest)
        }

        var locationJsonString = JSON.stringify(copyPointOfInterest(pointOfInterest));

        // DISPATCH EVENT
        if (locationPicker) {
            let locationPickerComponent = VWFSUtils.getParentElementByClass(component, "js-location-picker");
            if (locationPickerComponent) {
                VWFSUtils.dispatchEvent(locationPickerComponent, "locationChanged", {detail: {location: locationJsonString}});
            }
        } else {
            VWFSUtils.dispatchEvent(component, "locationChanged", {detail: {location: locationJsonString}});
        }

        if (isEmbedded && embeddingElement === "contactform") {
            hiddenPoiFormField.value = getPoiAsJsonFromLocationData(pointOfInterest, hiddenPoiFormField.getAttribute('data-poi-datatype'));
        }
    }

    /**
     * COPY POINT OF INTEREST
     * Creates a copy of the POI. Also removes fields with empty string value from contact-data.
     */
    const copyPointOfInterest = function (pointOfInterest) {

        // TEMPORARY REMOVE MARKER-PROPERTY TO GENERATE JSON
        var marker = pointOfInterest.marker;
        delete pointOfInterest.marker;
        var pointOfInterestString = JSON.stringify(pointOfInterest);
        pointOfInterest.marker = marker;

        var pointOfInterestCopy = JSON.parse(pointOfInterestString);

        // REMOVE EMPTY STRINGS FROM CONTACT-DATA (Causes rejection by checkout)
        if (pointOfInterestCopy.contactData) {

            if (pointOfInterestCopy.contactData.email === "") {
                delete pointOfInterestCopy.contactData.email;
            }
            if (pointOfInterestCopy.contactData.emails && pointOfInterestCopy.contactData.emails.length === 0) {
                delete pointOfInterestCopy.contactData.emails;
            }

            if (pointOfInterestCopy.contactData.telephoneCountryCode === "") {
                delete pointOfInterestCopy.contactData.telephoneCountryCode;
            }
            if (pointOfInterestCopy.contactData.telephoneNumber === "") {
                delete pointOfInterestCopy.contactData.telephoneNumber;
            }
            if (pointOfInterestCopy.contactData.website === "") {
                delete pointOfInterestCopy.contactData.website;
            }

        }

        return pointOfInterestCopy;

    }

    /**
     * RESET POI
     * When a POI Selector is reset.
     */
    const resetPoi = function () {
        selectedPointOfInterest = null;
        pointsOfInterest.map(poi => poi.selected = false)

        // UPDATE Company LOGO FIELD
        if (elementCompanyLogoViewEnabled) {
            updateCompanyLogoField(null);
        }

        // UPDATE Company NAME FIELD
        if (elementCompanyNameEnabled) {
            updateCompanyNameField(null);
        }

        // UPDATE ADDRESS FIELD
        if (elementAddressEnabled) {
            updateAddressField(null);
        }

        // UPDATE OPEN TIME FIELD
        if (elementOpenTimeEnabled) {
            updateOpenTimeField(null);
        }

        if (elementCompanyNameSearchEnabled) {
            companyNameSearchInput.value = '';
            updatePois(originalPointsOfInterest);
        }

        // UPDATE DROPDOWN
        if (elementDropdownEnabled) {
            dropDownPoiResults.value = "";
            removeErrorsDropdown();
        }

        // RESET RESULT LIST
        if (elementDetailedResultListEnabled) {
            deselectPoiInResultList();
            removeErrorsResultList();
        }

        // UPDATE HERE-MAP
        if (elementMapEnabled) {
            markerClustering.setMarkers(pointsOfInterest)

            // ADJUST THE MAP, THAT ALL POI ARE INSIDE OF THE MAP
            if (elementRadiusSearchEnabled || fitAllPoisAtStart) {
                markerClustering.fitMapToMarkers();
            }
        }
    }

// PRESELECT POI FROM DIS OR POI POOL VIA DIALOG CONFIGURATION OR VIA URL PARAMETER
    const preselectPoi = function () {
        if (pointsOfInterest) {
            for(let poi of pointsOfInterest) {
                if (poi
                    && (poi.poiUuid && poi.poiUuid.toLowerCase() === preselectedStationId.toLowerCase())
                    || (poi.companyId && poi.companyId.toLowerCase() === preselectedStationId.toLowerCase())
                    || (poi.stationNumber && poi.stationNumber.replaceAll(';', '').toLowerCase() === preselectedStationId.toLowerCase())) {
                    if ((dropDownPoiResults && dropDownPoiResults.options) || elementDetailedResultListEnabled) {
                        selectLocation(poi);
                    }
                    setTimeout((function () {
                        applyPoi(poi);
                    }), 200);
                    break;
                }
            }
        }
    }

    const selectLocation = function (pointOfInterest) {
        if (elementDetailedResultListEnabled) {
            let buttonInResultList = component.querySelector('#' + pointOfInterest.poiUuid);
            onResultListClick(buttonInResultList, false);
            return;
        }

        let dropdownOptions = Array.from(dropDownPoiResults.children);
        for (let option of dropdownOptions) {
            if (option.pointOfInterest && option.pointOfInterest.poiUuid === pointOfInterest.poiUuid) {
                option.selected = true;
                return;
            }
        }
    };

    /**
     * This function serves two use-cases and could be extended
     * Type 'RCOS' : depending on the vehicleService(case: RCOS), possible locations are excluded
     * Type 'Campaign' : check if a campaign exists as data attribute and return the result
     */

    const checkForAttribute = function (component, searchComponent, identifierClass, serviceAttribute, type, isACS = false) {
        if (VWFSUtils.getParentElementByClass(component, identifierClass) !== null) {
            if (searchComponent !== null && (isACS || VWFSUtils.isInsideOfElement(component, searchComponent))) {
                return type === 'RCOS' ? searchComponent.getAttribute(serviceAttribute) === 'RCOS' : searchComponent.getAttribute(serviceAttribute);
            }
        }
    }


// ----------------------------------------------------------
// EVENTS
// ----------------------------------------------------------


    /**
     * EVENT > ON PLACE SELECTED
     * This gets called from Radius-Search.
     */
    const onPlaceSelected = function (event) {
        // Reset Company Name input in case there were any previously inserted
        resetCompanyNameField()

        // GET PLACE FROM INPUT-FIELD
        const place = autocomplete.getPlace();
        const radius = radiusInput.value;

        // DON'T USE PLACE FROM AUTOCOMPLETE IF CURRENT LOCATION IS SELECTED AS LAST
        if (!event || event.type !== "change") {
            geoLocationLastUsed = false;
            if (geoLocationAccessDeniedMessage && !geoLocationAccessDeniedMessage.classList.contains('u-hide')) {
                geoLocationAccessDeniedMessage.classList.add('u-hide');
            }
        }

        // SET CENTER MARKER IF PLACE GOT LOCATION-DATA
        if (place && place.position && !geoLocationLastUsed) {
            markerClustering.setCenterMarker(place.position);
        }

        if (!initializing) {
            // LOAD POI DATA
            loadPois();
        }

    }

    /**
     * EVENT > ON PLACE NAVIGATION SELECTED
     * This gets called from Places-Navigation.
     */
    const onPlaceNavigationSelected = function () {

        // GET PLACE FROM INPUT-FIELD
        const place = autocompletePlacesNavigation.getPlace();

        // ONLY IF PLACE GOT LOCATION-DATA
        if (place && place.position && elementMapEnabled) {

            // GET COORDINATES FROM AUTOCOMPLETE-FIELD
            var location = place.position;

            // ADJUST MAP IF ENABLED
            setFocus(location);
        }

    }

    /**
     * EVENT > ON DROPDOWN POI CHANGE
     */
    const onDropdownPoiChange = function () {
        // SET VALIDATION FLAG
        setValidationFlag(dropDownPoiResults.selectedIndex !== 0);

        // GET SELECTED INDEX
        var selectedOption = dropDownPoiResults.options[dropDownPoiResults.selectedIndex];
        // APPLY POI
        if (selectedOption && selectedOption.pointOfInterest) {
            applyPoi(selectedOption.pointOfInterest);
        }

    }

    /**
     * EVENT > ON RESULT LIST CLICK
     */
    const onResultListClick = function (button, scrollIntoView) {

        // CHECK BUTTON
        selectPoiInResultList(button, scrollIntoView);

        // SET VALIDATION FLAG:
        component.setAttribute("data-product-configurator-validation-valid", "true");

        // APPLY POI
        if (button.poi) {
            applyPoi(button.poi);
        }
    }

    /**
     * EVENT > ON CLICK POI ON MAP
     */
    const onClickPoiOnMap = function (position, pointOfInterest, ui) {

        return function () {
            let content = new VWFS.poiSelector.InfoContentFactory(pointOfInterest, infoWindowShowButtonWebsiteEnabled, infoWindowShowButtonApplyEnabled, infoWindowButtonApplyText, getOpenTime, getAddress, getContactData, getLabelContentLink).buildInfoContent();
            content.addEventListener("applyPoi", onApplyPoiOnMap);

            // For all markers create only one bubble, if not created yet
            if (!infoWindow) {
                infoWindow = new H.ui.InfoBubble(position, {
                    content: content
                });
                ui.addBubble(infoWindow);
            } else {
                // Reuse existing bubble object
                infoWindow.setPosition(position);
                infoWindow.setContent(content);
                infoWindow.open();
            }
            // Move map's center to a clicked marker
            map.setCenter(position);

            VWFSUtils.dispatchEvent(document, "reInitLinks");
        }

    }

    /**
     * EVENT > ON APPLY POI ON MAP
     * If the user clicks on the Apply-Button inside of the InfoWindow (inside of the map) of a POI.
     */
    const onApplyPoiOnMap = function (event) {

        var pointOfInterest = event.detail.pointOfInterest;

        // APPLY POI
        applyPoi(pointOfInterest);

        // SELECT ITEM IN DROPDOWN
        if (elementDropdownEnabled) {

            // ITERATE OPTIONS OF DROPDOWN
            Array.from(dropDownPoiResults.children).forEach(option => {

                // SET DROPDOWN ITEM
                if (option.pointOfInterest && option.pointOfInterest === pointOfInterest) {
                    dropDownPoiResults.value = option.value;
                }

            });

        }

        if (elementDetailedResultListEnabled) {
            let buttonInResultList = component.querySelector('#' + selectedPointOfInterest.poiUuid);
            if (buttonInResultList) {
                onResultListClick(buttonInResultList, true)
            }
        }

        // CLOSE INFO-WINDOW
        infoWindow.close();

    }

    const filterDealerByPoiUuid = function (event) {
        let poiUuid = event.detail.poiUuid;
        let pointOfInterests = pointsOfInterest.filter((poi) => poi.poiUuid === poiUuid);
        if (pointOfInterests && pointOfInterests.length > 0) {
            // set selected Dealer from Dropdown and apply poi on the map
            let selectedPoi = pointOfInterests[0];
            if ((dropDownPoiResults && dropDownPoiResults.options) || elementDetailedResultListEnabled) {
                selectLocation(selectedPoi);
            }
            setTimeout((function () {
                applyPoi(selectedPoi);
            }), 200);

            VWFSUtils.dispatchEvent(component, 'dealerFiltered', {detail: {pointOfInterest: selectedPoi}});
        }
    }

    /**
     * add opening hours to an unordered list
     * @param pointOfInterest - dealer information
     * @param ul - html element to append opening hours to
     * @param listItemClass - additional class name
     * @returns {number} - number of days for which opening hours were added
     */
    const addOpeningHoursToList = function (pointOfInterest, ul, listItemClass) {
        let dayCounter = 0;

        for (let i = 0; i < pointOfInterest.openTime.length; i++) {
            let time = pointOfInterest.openTime[(i + 1) % 7];
            if (time.opening && time.closing) {
                dayCounter++;
                let day = document.createElement("li");
                day.className = "u-mb-none " + listItemClass || "";
                day.innerText = component.getAttribute('data-day-' + i) + ": ";
                day.innerText += time.opening + " - " + time.closing;
                ul.appendChild(day);
            }
        }

        if (pointOfInterest.returnOutsideOpeningHours && component.getAttribute("data-return-outside-opening-hours")) {
            let returnOutsideOfOpeningHoursElement = document.createElement("li")
            returnOutsideOfOpeningHoursElement.classList = "u-mb-none u-mt-small " + listItemClass || "";
            returnOutsideOfOpeningHoursElement.textContent = component.getAttribute("data-return-outside-opening-hours");
            ul.appendChild(returnOutsideOfOpeningHoursElement)
        }

        return dayCounter;
    }

    /**
     * toggle icon of list of opening hours from "expand" to "collapse"
     * @param container - html element with list of opening hours
     * @param icon - html element that needs to be toggled
     */
    function toggleOpeningHoursList(container, icon) {
        if (container.open) {
            icon.classList.remove("c-icon--[semantic-expand]");
            icon.classList.add("c-icon--[semantic-collapse]");
        } else {
            icon.classList.remove("c-icon--[semantic-collapse]");
            icon.classList.add("c-icon--[semantic-expand]");
        }
    }

// Create a node with an unordered list of days and opening hours
    const getOpenTime = function (pointOfInterest) {
        if (!pointOfInterest || !pointOfInterest.openTime) {
            return null;
        }

        var openTimeNode = document.createElement("p");
        openTimeNode.className = "u-mb-xsmall";

        var details = document.createElement("details");
        var summary = document.createElement("summary");
        summary.innerHTML = "<b>" + openingHoursLabel + "</b> "
        details.appendChild(summary)

        var icon = document.createElement("i");
        icon.classList.add("dmp-opening-hours-icon", "c-btn__icon", "c-icon", "c-icon--[semantic-expand]");
        summary.appendChild(icon);

        details.addEventListener("toggle", () => toggleOpeningHoursList(details, icon));

        var ul = document.createElement("ul");

        ul.classList.add('dmp-poi-selector-open-time__ul', "u-mb-xsmall");

        addOpeningHoursToList(pointOfInterest, ul);

        if (ul.hasChildNodes()) {
            details.appendChild(ul)
            openTimeNode.appendChild(details)
            return openTimeNode;
        } else {
            return null;

        }

    }

// Create a node with company logo
    const getCompanyLogo = function (pointOfInterest) {

        if (!pointOfInterest || (!pointOfInterest.image && !pointOfInterest.logoUrl)) {
            return null;
        }

        const image = pointOfInterest.image || pointOfInterest.logoUrl;

        if (image) {
            const imageNode = document.createElement("P");
            imageNode.className = "u-mb-xsmall";
            imageNode.innerHTML = "<img src=\"" + image + "\" alt=\"companyLogo\" class=\"dmp-poi-logo\">"
            return imageNode;
        } else {
            return null;
        }

    }

// Create a node with Company Name
    const getCompanyName = function (pointOfInterest) {

        if (!pointOfInterest || !pointOfInterest.companyName) {
            return null;
        }

        var companyNameString = pointOfInterest.companyName;

        if (companyNameString) {
            var companyNameNode = document.createElement("P");
            companyNameNode.className = "u-mb-xsmall u-text-left";
            companyNameNode.innerHTML = "<b>" + companyNameString + "</b>"
            return companyNameNode;
        } else {
            return null;
        }

    }

// Create a node with address data
    const getAddress = function (pointOfInterest) {

        if (!elementAddressEnabled || !pointOfInterest || !pointOfInterest.address) {
            return null;
        }

        var address = pointOfInterest.address;

        if (address.street || address.city || address.zipCode) {
            var addressNode = document.createElement("P");
            addressNode.className = "u-mb-xsmall";
            addressNode.innerHTML = "<b>" + component.getAttribute("data-info-window-address") + "</b>: "
            var addressString = "";
            if (address.street) {
                addressString += address.street + " ";

                if (address.houseNumber) {
                    addressString += address.houseNumber;
                }
                addressString += ", ";
            }

            if (address.zipCode) {
                addressString += address.zipCode + " ";
            }

            if (address.city) {
                addressString += address.city;
            }

            addressNode.innerHTML += addressString;
            return addressNode;

        } else {
            return null;
        }

    }

    // Create a node with contact data
    const getContactData = function (pointOfInterest) {

        if (!pointOfInterest || !pointOfInterest.contactData) {
            return null;
        }

        var contactData = pointOfInterest.contactData;

        if ((contactData.emails && contactData.emails.length > 0) || contactData.telephoneNumber || contactData.website) {
            var contactDataNode = document.createElement("p");
            contactDataNode.className = "u-mb-xsmall";

            var contactDataString = "";

            if (elementPhoneEnabled && contactData.telephoneNumber) {
                contactDataString += "<b>" + component.getAttribute("data-info-window-phone") + "</b>: "
                if (contactData.telephoneCountryCode) {
                    contactDataString += contactData.telephoneCountryCode + " ";
                }
                contactDataString += contactData.telephoneNumber + "<br>";
            }

            if (elementEmailEnabled && contactData.emails && contactData.emails.length > 0) {
                var emailsString = "<b>" + component.getAttribute("data-info-window-email") + "</b>: "
                contactData.emails.forEach(email => {
                    emailsString += email + "<br>";
                })
                contactDataString += emailsString;

            }

            contactDataNode.innerHTML = contactDataString;

            return contactDataNode;

        } else {
            return null;
        }

    }

    const getLabelContentLink = function () {
        return component.getAttribute("data-info-window-towebsite");
    };

    const removeErrorsResultList = function () {
        resultListContainer.classList.remove('dmp-is-error-container', 'is-error');
        // HIDE ERROR MESSAGE
        VWFSUtils.setClassUHide(hintGenericError);
        VWFSUtils.setClassUHide(hintRequiredError);
    }

    const removeErrorsDropdown = function () {
        dropDownPoiResults.classList.remove('is-error');
        // HIDE ERROR MESSAGE
        VWFSUtils.setClassUHide(hintGenericError);
        VWFSUtils.setClassUHide(hintRequiredError);
    }

    const setValidationFlag = function (valid) {
        component.setAttribute("data-product-configurator-validation-valid", valid ? "true" : "false");
        if (isEmbedded && embeddingElement === "locationpicker") {
            // TEST IF POI-SELECTOR IS PART OF LOCATION-PICKER-OPTION
            let locationPickerPoiSelector = VWFSUtils.getParentElementByClass(component, "js-location-picker-poi-selector")
            if (locationPickerPoiSelector) {
                let locationPickerComponent = VWFSUtils.getParentElementByClass(component, "js-location-picker");
                if (locationPickerComponent.hasAttribute('data-product-configurator-validation-valid') && locationPickerComponent.getAttribute('data-product-configurator-validation-valid') === "false") {
                    locationPickerComponent.setAttribute("data-product-configurator-validation-valid", dropDownPoiResults.selectedIndex === 0 ? "false" : "true");
                }
            }
        }
    }
}

/**
 * ON DOCUMENT LOADED
 */
document.addEventListener('DOMContentLoaded', function () {

    console.debug("INFO PoiSelector.js loaded");

    // ITERATE THROUGH ALL POI-SELECTOR-COMPONENTS AND INIT THEM
    document.querySelectorAll(".js-poiselector").forEach(function (component) {
        new VWFS.poiSelector.PoiSelector(component).init();
    });
});
